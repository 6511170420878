import React, { useState, useEffect } from 'react';

import Layout from '../../../components/layout';
import Content from '../../../components/licensed-in/content-container';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  return (
    <Layout {...props} activePage='licensed-in' id='licensed-in-page'>
      <div className='licensed-in-page-container'>
        <Content title='NEVADA INSURANCE FOR YOU' activeCard='nevada' image={'/Nevada_BG.jpeg'}>
          <p>
            With Apollo Insurance, you are more than a health insurance consumer. We value your needs and make it our priority to find affordable health
            insurance that works for our clients in Nevada. Our agents work with Anthem, the largest managed health insurance company in the Blue Cross Blue
            Shield family, as well as Ambetter, National General, Aliera, and United Healthcare.
          </p>
          <p>
            The health industry is constantly evolving and it can be difficult to keep up. Our agents work with you to determine your needs. Whether you’re
            looking for individual or group insurance, life insurance, or a supplemental plan, we can find preferred doctors in your network. Our Nevada agents
            are hard at work to make sure you have access to the most affordable health insurance options.
          </p>
          <p>
            We offer individual, family, disability, vision, dental, accident, critical illness, and gap insurance plans. With the increase in deductibles and
            out of pocket expenses of health insurance shifting to the consumer, gap insurance plans have become the most important and popular plan we sell
            today.
          </p>

          <span>Understanding HSA, FSA, and HRA</span>

          <p>
            Trust Apollo Insurance experts with your health insurance. We are experienced in working within the Nevada health industry’s difficult parameters to
            meet your needs. You shouldn’t have to struggle through the fundamentals of health insurance on your own – trust Apollo Insurance to help you find
            the provider that’s right for you! Contact us today for a quote!
          </p>
        </Content>
      </div>
    </Layout>
  );
};
